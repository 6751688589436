import React from "react";
import {
  Switch,
  Route,
  // Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as S6} from './s6.svg';

import Home from './Home';
import './App.scss';

function App() {
  const rand = Math.floor(1 + Math.random() * (8));

  // <Link to="/">Home</Link>
  // <Link to="/project">About</Link>

  return (
    <div className="App">
      <header className={"cover_"+rand}>
        <div className="black_gradient">
          <div className="inner">
            <h1>Andrew U</h1>
            <ul className="links">
              <li><a href="http://linkedin.com/in/andrewu14/" target="_blank" rel="noreferrer" title="LinkedIn Profile"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
              <li><a href="http://instagram.com/adru_u" target="_blank" rel="noreferrer" title="Instagram"><FontAwesomeIcon icon={faInstagram} /></a></li>
              <li><a href="http://society6.com/adru" target="_blank" rel="noreferrer" title="Society6"><S6 fill="#FFFFFF" width="16" height="12" /></a></li>
              <li><a href="mailto:me@andrewu.net" target="_blank" rel="noreferrer" title="Email me"><FontAwesomeIcon icon={faEnvelope} /></a></li>
            </ul>
            <div className="clear"></div>
            <a href="andrewu.pdf" target="_blank" rel="noreferrer" className="resume">PDF Résumé</a>
          </div>
        </div>
      </header>

      <div>
        <Switch>
          <Route path="/project">
            About
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
