import React from "react";

import Intro from './Intro';

function Home() {
  return(
    <section id="content">
      <Intro />
    </section>
  );
}

export default Home;
